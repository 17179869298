import React, { useState, useEffect } from "react";
import axios from "axios";
import { ethers } from "ethers";
import ReactMarkdown from "react-markdown";
import { getDoc, doc, setDoc } from "firebase/firestore"; // Import Firestore functions
import { db, auth } from "../../services/firebase"; // Import Firestore and auth
import "./CryptoChatWallet.scss";

const CryptoChatWallet = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [wallet, setWallet] = useState(null);
  const [benefits, setBenefits] = useState("");
  const [ethPrice, setEthPrice] = useState(null);
  const [loading, setLoading] = useState(false);
  const [walletAddress, setWalletAddress] = useState(null);
  const [showPrivateKey, setShowPrivateKey] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const userDoc = doc(db, "users", user.uid);
        const docSnapshot = await getDoc(userDoc);
        if (docSnapshot.exists() && docSnapshot.data().walletAddress) {
          setWalletAddress(docSnapshot.data().walletAddress);
        }
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []); // Empty dependency array

  const handleSendMessage = async () => {
    if (input.trim() === "") return;

    const userMessage = { user: "You", text: input };
    setMessages([...messages, userMessage]);

    try {
      const generativeResponse = await axios.post(
        "https://generativelanguage.googleapis.com/v1beta/models/gemini-1.5-flash-latest:generateContent?key=YOUR_API_KEY",
        {
          contents: [
            {
              parts: [
                {
                  text: `Ethereum: ## How Ethereum Works: A Simplified Explanation

**Ethereum is a decentralized platform**, meaning it’s not controlled by any single entity. Here’s a simplified breakdown:

## 1. Blockchain:
* Ethereum uses a blockchain, a public ledger that records all transactions. Each transaction is grouped into a block and added to the chain.

## 2. Smart Contracts:
* Ethereum supports smart contracts, which are self-executing contracts with the terms directly written into code.

## 3. Mining:
* Transactions on the Ethereum network are verified by miners who solve complex mathematical problems to add blocks to the blockchain.

## 4. Transactions:
* Sending Ether (ETH) involves broadcasting a transaction to the network, which is then verified and added to a block.

## 5. Decentralization:
* Ethereum’s decentralized nature means it’s resistant to censorship and manipulation.

**In summary:** Ethereum is a decentralized platform that enables the creation of smart contracts and decentralized applications (dApps). It uses blockchain technology to ensure secure and transparent transactions.`,
                },
              ],
            },
          ],
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const generatedText =
        generativeResponse.data?.candidates?.[0]?.content?.parts?.[0]?.text ||
        "No response generated.";
      const chatMessage = {
        user: "AI",
        text: generatedText,
      };

      setMessages((prevMessages) => [...prevMessages, chatMessage]);
    } catch (error) {
      console.error("Error fetching data:", error);
      const errorMessage = { user: "AI", text: "Error generating response." };
      setMessages((prevMessages) => [...prevMessages, errorMessage]);
    }

    setInput("");
  };

  const handleCreateWallet = async () => {
    setLoading(true);
    const newWallet = ethers.Wallet.createRandom();
    setWallet(newWallet);

    try {
      if (auth.currentUser) {
        const userDoc = doc(db, "users", auth.currentUser.uid);
        await setDoc(userDoc, { walletAddress: newWallet.address }, { merge: true });
      }

      const response = await axios.post(
        "https://generativelanguage.googleapis.com/v1beta/models/gemini-1.5-flash-latest:generateContent?key=AIzaSyANhQuTjQrUsPbF43Z3ZfyoLmijqcn9fZs",
        {
          contents: [
            {
              parts: [
                {
                  text: `Give me 5 ways it would be beneficial to someone that has never created an Ethereum wallet before.`,
                },
              ],
            },
          ],
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const benefitsText =
        response.data?.candidates?.[0]?.content?.parts?.[0]?.text ||
        "No benefits generated.";
      setBenefits(benefitsText);
    } catch (error) {
      console.error("Error fetching benefits:", error);
      setBenefits("Error generating benefits.");
    }

    try {
      const coinbaseResponse = await axios.get(
        "https://api.coinbase.com/v2/prices/ETH-USD/spot"
      );
      const ethPrice = coinbaseResponse.data.data.amount;
      setEthPrice(ethPrice);
    } catch (error) {
      console.error("Error fetching Ethereum price:", error);
      setEthPrice("Error fetching price");
    }

    setLoading(false);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSendMessage();
    }
  };

  return (
    <div className="crypto-chat-wallet">
      <h2>Crypto AI Chat & Wallet Creation</h2>

      {/* Wallet Creation Section */}
      <div className="wallet-section">
        <h3>Create Ethereum Wallet</h3>
        {walletAddress ? (
          <div>
            <p><strong>Address:</strong> {walletAddress}</p>
          </div>
        ) : (
          <button onClick={handleCreateWallet} disabled={loading}>
            {loading ? "Creating Wallet..." : "Create Wallet"}
          </button>
        )}
        {loading && <p>Loading...</p>}
        {wallet && (
          <div className="wallet-info">
            <p>
              <strong>Address:</strong> {wallet.address}
            </p>
            <p>
              <strong>Private Key:</strong> 
              {showPrivateKey ? wallet.privateKey : <button onClick={() => setShowPrivateKey(true)}>Show Private Key</button>}
            </p>
            {showPrivateKey && (
              <p>Please write down your private key before continuing.</p>
            )}
          </div>
        )}
        {ethPrice && (
          <div className="eth-price">
            <p>
              <strong>Current ETH Price (USD):</strong> ${ethPrice}
            </p>
          </div>
        )}
      </div>

      {/* Chat Section */}
      {walletAddress && (
        <div className="chat-section">
          <h3>Crypto Chat</h3>
          <div className="chat-messages">
            {messages.map((message, index) => (
              <div key={index} className="message">
                <strong>{message.user}:</strong>
                <ReactMarkdown>{message.text}</ReactMarkdown>
              </div>
            ))}
          </div>
          <div className="input-group">
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder="Type your message..."
            />
            <button onClick={handleSendMessage}>Send</button>
          </div>
        </div>
      )}

      {/* Benefits Section */}
      {benefits && (
        <div className="benefits-section">
          <h3>Benefits of Creating an Ethereum Wallet</h3>
          <ReactMarkdown>{benefits}</ReactMarkdown>
        </div>
      )}
    </div>
  );
};

export default CryptoChatWallet;
