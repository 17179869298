import React, { useState } from 'react';
import Modal from 'react-modal';
import { signInWithPopup, signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import { auth, provider, db } from '../../services/firebase';
import { doc, setDoc } from "firebase/firestore"; // Import Firestore functions
import './FirebaseAuthModal.scss'; // Import the styles

Modal.setAppElement('#root');

const FirebaseAuthModal = ({ setUser, isOpen, onRequestClose }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSignUp, setIsSignUp] = useState(false);
  const [error, setError] = useState(null);

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      await setDoc(doc(db, "users", user.uid), {
        email: user.email,
        provider: "Google"
      }, { merge: true });
      setUser(user);
      onRequestClose();
    } catch (error) {
      console.error("Error signing in with Google: ", error);
      setError(error.message);
    }
  };

  const handleEmailSignIn = async (e) => {
    e.preventDefault();
    try {
      let userCredential;
      if (isSignUp) {
        userCredential = await createUserWithEmailAndPassword(auth, email, password);
      } else {
        userCredential = await signInWithEmailAndPassword(auth, email, password);
      }
      const user = userCredential.user;
      await setDoc(doc(db, "users", user.uid), {
        email: user.email,
        provider: "Email"
      }, { merge: true });
      setUser(user);
      onRequestClose();
    } catch (error) {
      console.error("Error signing in with email and password: ", error);
      setError(error.message);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Authentication Modal"
      className="auth-modal center-modal"
      overlayClassName="auth-modal-overlay"
    >
      <div className="auth-modal-content">
        <div className="auth-modal-image">
          <img src="/path/to/your/image.png" alt="Welcome" />
        </div>
        <div className="auth-modal-form">
          <button className="auth-modal-close" onClick={onRequestClose}>Close</button>
          <h2>Welcome!</h2>
          <form onSubmit={handleEmailSignIn}>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email Address"
              required
            />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              required
            />
            <button type="submit">{isSignUp ? 'Sign Up' : 'Sign In'}</button>
          </form>
          {error && <p>{error}</p>}
          <div className="auth-modal-options">
            <button onClick={handleGoogleSignIn}>Sign in with Google</button>
            <button onClick={() => setIsSignUp(!isSignUp)}>
              {isSignUp ? 'Switch to Sign In' : 'Switch to Sign Up'}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FirebaseAuthModal;
