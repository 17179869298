import React, { useState, useEffect } from "react";
import "./Home.scss";
import CryptoChatWallet from "../../components/cryptochatwallet"; // Import the CryptoChatWallet component
import FirebaseAuthModal from "../../components/firebaseauth"; // Import the FirebaseAuthModal component
import { auth } from "../../services/firebase"; // Import the Firebase auth object

const Home = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [user, setUser] = useState(null);

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  useEffect(() => {
    // Check if user is logged in
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const handleLogout = async () => {
    try {
      await auth.signOut();
      setUser(null);
    } catch (error) {
      console.error("Error signing out", error);
    }
  };

  return (
    <div className="home">
      <header className="home-header">
        <h1>Welcome to Our Cryptocurrency Platform</h1>
        <p>
          Explore the world of cryptocurrency with real-time data and wallet
          creation.
        </p>
        {!user && <button onClick={openModal}>Sign In</button>}
        {user && <button onClick={handleLogout}>Logout</button>}

        <FirebaseAuthModal
          setUser={setUser}
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
        />
        {user && <CryptoChatWallet />}
      </header>
    </div>
  );
};

export default Home;
