// src/services/firebase/index.js
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore"; // Import Firestore

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBooeFgLXY4R-dTySD3BrZNB2y9VJdE6R0",
    authDomain: "intelly-765bd.firebaseapp.com",
    databaseURL: "https://intelly-765bd-default-rtdb.firebaseio.com",
    projectId: "intelly-765bd",
    storageBucket: "intelly-765bd.appspot.com",
    messagingSenderId: "179764461813",
    appId: "1:179764461813:web:e93cebbab975dfc95345bc",
    measurementId: "G-L34ME16VLG"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const db = getFirestore(app); // Initialize Firestore

export { auth, provider, db };
